import _colors from "./colors";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var colors = _colors;

exports = function () {
  //
  // Extends prototype of native string object to allow for "foo".red syntax
  //
  var addProperty = function (color, func) {
    String.prototype.__defineGetter__(color, func);
  };

  addProperty("strip", function () {
    return colors.strip(this || _global);
  });
  addProperty("stripColors", function () {
    return colors.strip(this || _global);
  });
  addProperty("trap", function () {
    return colors.trap(this || _global);
  });
  addProperty("zalgo", function () {
    return colors.zalgo(this || _global);
  });
  addProperty("zebra", function () {
    return colors.zebra(this || _global);
  });
  addProperty("rainbow", function () {
    return colors.rainbow(this || _global);
  });
  addProperty("random", function () {
    return colors.random(this || _global);
  });
  addProperty("america", function () {
    return colors.america(this || _global);
  }); //
  // Iterate through all default styles and colors
  //

  var x = Object.keys(colors.styles);
  x.forEach(function (style) {
    addProperty(style, function () {
      return colors.stylize(this || _global, style);
    });
  });

  function applyTheme(theme) {
    //
    // Remark: This is a list of methods that exist
    // on String that you should not overwrite.
    //
    var stringPrototypeBlacklist = ["__defineGetter__", "__defineSetter__", "__lookupGetter__", "__lookupSetter__", "charAt", "constructor", "hasOwnProperty", "isPrototypeOf", "propertyIsEnumerable", "toLocaleString", "toString", "valueOf", "charCodeAt", "indexOf", "lastIndexOf", "length", "localeCompare", "match", "repeat", "replace", "search", "slice", "split", "substring", "toLocaleLowerCase", "toLocaleUpperCase", "toLowerCase", "toUpperCase", "trim", "trimLeft", "trimRight"];
    Object.keys(theme).forEach(function (prop) {
      if (stringPrototypeBlacklist.indexOf(prop) !== -1) {
        console.log("warn: ".red + ("String.prototype" + prop).magenta + " is probably something you don't want to override.  " + "Ignoring style name");
      } else {
        if (typeof theme[prop] === "string") {
          colors[prop] = colors[theme[prop]];
          addProperty(prop, function () {
            return colors[prop](this || _global);
          });
        } else {
          var themePropApplicator = function (str) {
            var ret = str || this || _global;

            for (var t = 0; t < theme[prop].length; t++) {
              ret = colors[theme[prop][t]](ret);
            }

            return ret;
          };

          addProperty(prop, themePropApplicator);

          colors[prop] = function (str) {
            return themePropApplicator(str);
          };
        }
      }
    });
  }

  colors.setTheme = function (theme) {
    if (typeof theme === "string") {
      console.log("colors.setTheme now only accepts an object, not a string. " + "If you are trying to set a theme from a file, it is now your (the " + "caller's) responsibility to require the file.  The old syntax " + "looked like colors.setTheme(__dirname + " + "'/../themes/generic-logging.js'); The new syntax looks like " + "colors.setTheme(require(__dirname + " + "'/../themes/generic-logging.js'));");
      return;
    } else {
      applyTheme(theme);
    }
  };
};

export default exports;